<template>
    <span class="timeSource">
        <span>上传时间:{{data.time}}</span>
        <span>|</span>
        <span>数据来源:{{data.source}}</span>
    </span>
</template>

<script>
export default {
    name: 'TimeSource',

    props:{
        data:Object,
    }

   
};
</script>

<style lang="scss" scoped>
 .timeSource{
     height:24px;
       background: rgba(40, 167, 225, 0.1);
        border-radius: 2px;
        border: 1px solid rgba(40, 167, 225, 0.2);
      span{
        display: inline-block;
        padding:4px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #28A7E1;
      }
    }
</style>