<template>
  <v-chart
    class="chart"
    :option="option"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { PieChart, xAxis } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  BarChart,
  GridComponent,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "pie2",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          title: "",
          main: [
              {  },
              { },
              {  }
            ],
        };
      },
    },
  },
  mounted(){
    if(this.data){
      this.option = this.data;
    }
 
  },
  watch: {
    data(nv, ov) {

        // alert(JSON.stringify(nv))
      this.option =  {
        title: {
          text: this.data.title,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: " {b} : {c} ({d}%)",
        },
        legend: {
          bottom: 20,
          type:'scroll',
          left: "center",
          // data: ["a", "b", "c", "d"],
          data:this.data.main.map(item=>item.name)
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            selectedMode: "single",
            data:this.data.main,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      }
    
    }
  },
  data() {
    return {
      option: {
        title: {
          text: this.data.title,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: " {b} : {c} ({d}%)",
        },
        legend: {
          bottom: 20,
          type:'scroll',  
          
          left: "center",
          // data: ["a", "b", "c", "d"],
          data:this.data.main.map(item=>item.name)
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            selectedMode: "single",
            data:this.data.main,
            // data: [
            //   { value: 735, name: "a" },
            //   { value: 510, name: "b" },
            //   { value: 434, name: "c" },
            //   { value: 3135, name: "d" },
            // ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 440px;
}
</style>