import request from '@/utils/request'
import requestMock from '@/utils/requestMock'
//mock数据使用  requestMock需要换成request 删除此行

//城市人口列表
// export function citypopulation(params){
//     return request({
//       url: '/frontend/bigdata/city-plate/population',
//       method: 'post',
//       params
//     })
//   }


//城市经济 --gdp  获取城市年
// export function cityYear(params){
//   return requestMock({
//     url: '/mock/gdp_cityyear.json',
//     method: 'get',
//     params
//   })
// }


export function cityYearByBlock(params) {
  return request({
    url: '/frontend/bigdata/city-plate/population-range-year',
    method: 'post',
    params
  })
}



export function  rangeYearByBlock(params) {
  return request({
    url: '/frontend/bigdata/city-plate/population-range-year',
    method: 'post',
    params
  })
}



export function cityYear(params) {
  return request({
    url: '/frontend/bigdata/economic/condition',
    method: 'post',
    params
  })
}
//城市经济 --gdp nge
// export function gdpData(params){
//   return requestMock({
//     url: '/mock/gdp.json',
//     method: 'get',
//     params
//   })
// }
export function gdpData(params) {
  return request({
    url: '/frontend/bigdata/economic/data',
    method: 'post',
    params
  })
}
//城市经济 --gdp 下载
// export function downloadGdpExcel(params){
//     return requestMock({
//       url: '/mock/gdp_download.json',
//       method: 'get',
//       params
//     })
//   }
export function downloadGdpExcel(params) {
  return request({
    url: '/frontend/bigdata/economic/excel',
    method: 'post',
    params
  })
}



//城市经济 --dian  获取城市年
export function DianCityYear(params) {
  return requestMock({
    url: '/mock/dian_cityyear.json',
    method: 'get',
    params
  })
}
//城市经济 --dian 
export function dianData(params) {
  return requestMock({
    url: '/mock/dian.json',
    method: 'get',
    params
  })
}
//城市经济 --dian 下载
export function downloadDianExcel(params) {
  return requestMock({
    url: '/mock/dian_download.json',
    method: 'get',
    params
  })
}

export function dianRangeYear(params) {
  return requestMock({
    url: '/mock/dian_rangeYear.json',
    method: 'get',
    params
  })
}

export function gdpRangeYear(params) {
  return request({
    url: '/frontend/bigdata/economic/range-year',
    method: 'post',
    params
  })
}



// export function gdpRangeYear(params){
//   return requestMock({
//     url: '/mock/gdp_rangeYear.json',
//     method: 'get',
//     params
//   })
// }


///========================================================================以下为通用的方法

// 数据获取通用 (适用于通用参数 city_plate_id 、cateid  、end_year 、start_year）


export function dataByBlock(params) {
  return request({
    url: '/frontend/bigdata/city-plate/population',
    method: 'post',
    params
  })

}





export function dataByCateid(params) {
  // const isApi = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  // let url = "";
  // switch (params.cateid) {
  //   case 2:
  //     url = '/frontend/bigdata/economic/data'
  //     break;
  //   case 3:
  //     url = '/frontend/bigdata/economic/data'
  //     break;
  //   case 4:
  //     url = '/mock/shexiao.json'
  //     break;
  //   case 5:
  //     url = '/mock/jinchukou.json'
  //     break;
  //   case 6:
  //     url = '/mock/waizi.json'
  //     break;
  //   case 7:
  //     url = '/mock/dian.json'
  //     break;
  //   case 8:
  //     url = '/mock/caishou.json'
  //     break;
  //   case 9:
  //     url = '/mock/caizhi.json'
  //     break;
  //   case 10:
  //     url = '/mock/cun.json'
  //     break;
  //   case 11:
  //     url = '/mock/zhuti.json'
  //     break;
  //   case 12:
  //     url = '/mock/you.json'
  //     break;
  //   case 13:
  //     url = '/mock/zhuan.json'
  //     break;
  //   case 14:
  //     url = '/mock/xue.json'
  //     break;
  //   case 15:
  //     url = '/mock/yi.json'
  //     break;
  //   case 16:
  //     url = '/mock/ren.json'
  //     break;
  //   case 17:
  //     url = '/mock/yan.json'
  //     break;
  //   case 18:
  //     url = '/mock/zhong.json'
  //     break;
  //   case 19:
  //     url = '/mock/qi.json'
  //     break;
  //   case 20:
  //     url = '/mock/qiang.json'
  //     break;
  //   case 21:
  //     url = '/mock/shouzhi.json'
  //     break;

  // }

  //   console.log("url=======", params,isApi.indexOf(params.cateid))
  //  if(isApi.indexOf(params.cateid)>-1){
  //   return request({
  //     url: '/frontend/bigdata/economic/data',
  //     method: 'post',
  //     params
  //   })
  //  }else{
  //   return requestMock({
  //     url: url,
  //     method: 'get',
  //     params
  //   })
  //  }
  return request({
    url: '/frontend/bigdata/economic/data',
    method: 'post',
    params
  })




}
// 附加表通用数据 (适用于通用参数 year 和 cateid city_plate_id)
export function addTableDataByCateid(params) {

  // const isApi = [8,9,10,11,12,13,14,1001,1002,1003,201,202,301,601];
  // let url = "/mock/table" + params.cateid + ".json";
  // if(isApi.indexOf(params.cateid)>-1){

  // }else{
  //   return requestMock({
  //     url: url,
  //     method: 'get',
  //     params
  //   })
  // }
  let url = "/frontend/bigdata/economic/data"
  return request({
    url: url,
    method: 'post',
    params
  })
}

//  附加表通用下载excel (适用于通用参数 year 和 cateid city_plate_id)
export function downloadExcelByCateid(params) {
  // const isApi = [2,3,4,5,6,7,8,9,10,11,12,13,14,201,202,301,601,1001,1002,1003];
  // if(isApi.indexOf(params.cateid)>-1){

  // }else{
  //   return requestMock({
  //     url: '/mock/dian_download.json',
  //     method: 'get',
  //     params
  //   })
  // }
  return request({
    url: '/frontend/bigdata/economic/excel',
    method: 'post',
    params
  })
}

// 城市和年的范围获取通用 (适用于通用参数 city_plate_id 、cateid  、end_year 、start_year）
export function cityYearByCateid(params) {
  // const isApi = [2,3,4,5,6,7,8,9,10,11,12,13,14];
  // if(isApi.indexOf(params.cateid)>-1){

  // }else{
  //   return requestMock({
  //     url: '/mock/gdp_cityyear.json',
  //     method: 'get',
  //     params
  //   })
  // }
  return request({
    url: '/frontend/bigdata/economic/condition',
    method: 'post',
    params
  })


}

// 年范围Excel下载通用 (适用于通用参数 city_plate_id 、cateid  、end_year 、start_year）
export function RangeYearDownloadExcelByCateid(params) {


  // const isApi = [2,3,4,5,6,7,8,9,10,11,12,13,14];
  // if(isApi.indexOf(params.cateid)>-1){


  // }else{
  //   return requestMock({
  //     url: '/mock/dian_download.json',
  //     method: 'get',
  //     params
  //   })
  // }
  return request({
    url: '/frontend/bigdata/economic/excel',
    method: 'post',
    params
  })
}
//切换城市获取的 年份区间通用(适用于通用参数 city_plate_id 、cateid ）
export function rangeYearByCateid(params) {

  // const isApi = [2,3,4,5,6,7,8,9,10,11,12,13,14];
  // if(isApi.indexOf(params.cateid)>-1){


  // }else{
  //   return requestMock({
  //     url: '/mock/gdp_rangeYear.json',
  //     method: 'get',
  //     params
  //   })
  // }

  return request({
    url: '/frontend/bigdata/economic/range-year',
    method: 'post',
    params
  })

}